<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" />
        <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" />
        <!-- End Header Area -->

        <!-- Start Slider Area -->
        <div class="slide slide-style-2 slider-video-bg d-flex align-center justify-center overlay-fix"
            data-black-overlay="1" style="height:30vh !important;">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2 class="heading-title1 text-left font-weight-bold text-capitalize hidden-mobile"
                            style="font-size: 81px !important; line-height: 95px !important; letter-spacing: 2px !important;">
                            Telecommunication
                        </h2>
                        <div class="hidden-md-and-up">
                            <br>
                            <br>
                        </div>
                        <h2 class="heading-title1 text-left font-weight-bold text-capitalize hidden-md-and-up"
                            style="font-size: 31px !important; line-height: 95px !important; letter-spacing: 2px !important;">
                            Telecommunication
                        </h2>
                    </v-col>
                    <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                        <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light hidden-mobile"
                            style="line-height: 40px !important;">
                            Navigating the Telecommunications Epoch
                        </h5>
                        <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light hidden-md-and-up"
                            style="line-height: 40px !important; font-size: 20px !important;">
                            Navigating the Telecommunications Epoch
                        </h5>
                    </v-col>
                </v-row>
            </v-container>

            <div class="photo-background hidden-mobile">
                <span>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, rgba(42, 42, 46, 0.20) 50%, rgb(41, 41, 44));"></div>
                    <img src="https://inplatform.sibernetik.co.id/siber-web/images/telecommunication.jpg" alt=""
                        style="width: 100%;" />
                </span>
            </div>
            <div class="photo-background hidden-md-and-up">
                <span>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, rgba(42, 42, 46, 0.20) 50%, rgb(41, 41, 44));"></div>
                    <img src="https://inplatform.sibernetik.co.id/siber-web/images/telecommunication.jpg" alt=""
                        style="height: 100%;" />
                </span>
            </div>
        </div>
        <!-- End of Slider -->

        <!-- Start Content -->
        <!-- <div class="rn-contact-area rn-section-gap bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-center mb--20">
                            <h4 class="heading-title">Banking & Financial Services</h4>
                            <h3 class="heading-title mb-5">
                                Lorem Ipsum available
                            </h3>
                            <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                            <p style="text-align: center;" class="my-8">PSrE-hub is a pioneering system that allows users to process digital signatures for<br>various type of documents safely and seamlessly which can be integrated with various Certificate Authorities.</p>
                        </div>
                        <ServicePsre/>
                    </div>
                </v-container>
            </div>
        </div>   -->
        <div class="rn-contact-area bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-left mb--20">
                            <h4 class="heading-title">The Telecommunications Landscape</h4>
                            <h3 class="heading-title mb-5">
                                Empowering the Digital Future
                            </h3>
                            <hr color="#dd8732" style="margin: auto; margin-bottom: 20px; border-top: 4px  solid #dd8732">
                            <v-row>
                                <v-col lg="8" md="12">
                                    <p style="text-align: left; ">
                                        The telecommunications industry is a marvel of our modern era, a vast and intricate
                                        network
                                        that has transformed the way we live, work, and connect. From the humble beginnings
                                        of
                                        telegraphs and telephones to the high-speed internet and mobile networks of today,
                                        it has
                                        consistently been at the forefront of technological innovation. As the world becomes
                                        increasingly digital, the role of telecommunications has expanded beyond mere
                                        connectivity.
                                        It now stands as the backbone of global economies, facilitating commerce, powering
                                        innovations, and bridging distances.
                                    </p>
                                    <p style="text-align: left; " class="my-8">
                                        Amidst this ever-evolving landscape, Sibernetik Integra Data emerges as a beacon of
                                        transformative solutions. Our journey, rooted in Indonesia since 2017, has been one
                                        of
                                        continuous growth and innovation. We've witnessed firsthand the transformative power
                                        of
                                        telecommunications, and we've been instrumental in shaping its trajectory. Our
                                        commitment is
                                        to harmonize the melodies of connection, innovation, and security, ensuring that the
                                        digital
                                        age is accessible, secure, and beneficial for all.
                                    </p>
                                    <p style="text-align: left; " class="my-8">
                                        In a world where technology is rapidly advancing and consumer demands are
                                        ever-shifting, the
                                        insights are manifold. Yet, it's these very insights that drive us at
                                        Sibernetik. With a
                                        fusion of local insights and global technological prowess, we are uniquely
                                        positioned to
                                        navigate the intricate dance of telecommunications, crafting solutions that resonate
                                        with
                                        the needs of the modern world.
                                    </p>
                                </v-col>
                                <v-col lg="4" class="hidden-mobile">
                                    <v-img src="https://inplatform.sibernetik.co.id/siber-web/images/phone-user.jpg"
                                        height="100%" style="padding: 10px !important" />
                                </v-col>
                            </v-row>
                        </div>
                        <!-- <ServicePsre /> -->
                    </div>
                </v-container>
            </div>
        </div>

        <div class="rn-contact-area bg_color--1" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="text-center hidden-mobile">
                            <h2>The States of Telecommunication</h2>
                            <hr color="#dd8732" style="margin:auto; margin-bottom: 20px; border-top: 4px solid #dd8732">
                        </div>

                        <div class="text-center hidden-md-and-up">
                            <h3>The States of Telecommunication</h3>
                            <hr color="#dd8732" style="margin:auto; margin-bottom: 20px; border-top: 4px solid #dd8732">
                        </div>
                        <v-row class="hidden-mobile">
                            <v-col :lg="4" md="12" sm="12" cols="12" v-for="(service, i) in insights" :key="i">
                                <div class="single-service service__style--siber flex"
                                    :style="`display: flex; justify-content: center; align-items: center; padding: 0px !important;`">

                                    <v-img :src="service.icon" width="500px" height="500px" />
                                    <div class="bg_color--1 pa-2"
                                        :style="`position: absolute; bottom: 0%; width: 100%; height:40%; color: white; background-color:#dd8732; opacity: 0.9;`">
                                        <!-- <div class="icon" v-html="iconSvg(service.icon)"></div> -->
                                        <h4 :style="`width: 100%;`">
                                            {{ service.title }}</h4>
                                        <!-- <div class="content" style="width: 500px;"> -->
                                        <p style="font-size: 15px;">
                                            {{ service.desc }}
                                        </p>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row class="hidden-md-and-up">
                            <v-col :lg="4" md="12" sm="12" cols="12" v-for="(service, i) in insights" :key="i"
                                style="scale: 0.8;">
                                <div class="single-service service__style--siber flex"
                                    :style="`display: flex; justify-content: center; align-items: center; padding: 0px !important;`">

                                    <v-img :src="service.icon" width="500px" height="500px" />
                                    <div class="bg_color--1 pa-2"
                                        :style="`position: absolute; bottom: 0%; width: 100%; height:40%; color: white; background-color:#dd8732; opacity: 0.9;`">
                                        <!-- <div class="icon" v-html="iconSvg(service.icon)"></div> -->
                                        <h4 :style="`width: 100%;`">
                                            {{ service.title }}</h4>
                                        <!-- <div class="content" style="width: 500px;"> -->
                                        <p style="font-size: 15px;">
                                            {{ service.desc }}
                                        </p>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </div>
        </div>
        <div class="rn-portfolio-area rn-section-gap bg_color--5" id="recognitionAwards" style="">
            <div class="portfolio-sacousel-inner pb--30">
                <v-container class="fade-in">
                    <v-row>
                        <v-col lg="12">
                            <div class="section-title section-title--2 text-center mb--20">
                                <h3 class="heading-title mb-5">
                                    Telecommunication Insights
                                </h3>
                                <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                                <p class="my-8 hidden-mobile" style="text-align: center; font-size: 24px !important">The
                                    realm of
                                    telecommunications is vast, dynamic, and ever-evolving. As the very foundation of our
                                    interconnected digital world, it plays a pivotal role in shaping societies, economies,
                                    and individual experiences. This section delves deep into the intricacies of the
                                    telecommunications industry, offering insights into its current state, the challenges it
                                    faces, and the innovations on the horizon.</p>
                                <p class="my-8 hidden-md-and-up" style="text-align: center; font-size: 20px !important">The
                                    realm of
                                    telecommunications is vast, dynamic, and ever-evolving. As the very foundation of our
                                    interconnected digital world, it plays a pivotal role in shaping societies, economies,
                                    and individual experiences. This section delves deep into the intricacies of the
                                    telecommunications industry, offering insights into its current state, the challenges it
                                    faces, and the innovations on the horizon.</p>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <div class="card-slider hidden-mobile">
                    <div class="buttons" @click="slideLeft" style="cursor: pointer;">
                        <!-- <v-btn @click="slideLeft" color="transparent" elevation="0" flat> -->
                        <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                        <!-- </v-btn> -->
                    </div>
                    <transition-group name="card" tag="div" class="card-container">
                        <v-card :class="'card-solutions-' + index" v-for="(card, index) in cards" :key="card.id">
                            <v-img :src="card.image" height="100%" />
                            <div class="bg_color--1 pa-2"
                                :style="`position: absolute; bottom: 0%; width: 100%;${index == 2 ? 'background-color:#dd8732; opacity: 0.9;padding: 20px 20px 60px 20px !important;' : 'opacity: 0.5; padding: 20px 20px 20px 20px !important;'}`">
                                <!-- <div class="icon" v-html="iconSvg(service.icon)"></div> -->
                                <h3 :style="`width: 100%;`">
                                    {{ card.title }}</h3>
                                <!-- <div class="content" style="width: 500px;"> -->
                                <p style="font-size: 15px;" v-if="index == 2">
                                    {{ card.description }}
                                </p>
                                <!-- </div> -->
                            </div>
                        </v-card>
                        <!-- <Card v-for="(card, index) in cards" :key="card.id" :title="card.title" :year="card.year"
                            :link="card.link" style="position: relative;" /> -->
                    </transition-group>
                    <div class="buttons" @click="slideRight" style="cursor: pointer;">
                        <!-- <v-btn @click="slideRight" color="transparent" elevation="0" flat> -->
                        <v-icon elevation="0" flat x-large>mdi-chevron-right</v-icon>
                        <!-- </v-btn> -->
                    </div>
                </div>
                <div class="card-slider hidden-md-and-up" style="position: relative;">
                    <v-btn @click="slideRight" icon
                        style="background-color: #dd8732;cursor: pointer;top: 50%; right: 0%; position: absolute; z-index: 10;">
                        <v-icon elevation="0" flat x-large>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn @click="slideLeft" icon
                        style="background-color: #dd8732;cursor: pointer; top: 50%; left: 0%; position: absolute; z-index: 10;">
                        <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <!-- <div class="buttons" @click="slideLeft(challenges)"
                                    style="cursor: pointer;left: 0%; position: absolute;">
                                    <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                                </div> -->
                    <v-card
                        :style="`width:100%; height: 500px; transform: translateX(0); transition: transition ease 0.3s; transform: scale(0.9)`">
                        <v-img :src="cards[0].image" height="100%" />
                        <v-btn elevation="0" icon small
                            style="background-color: #dd8732; cursor: pointer; top: 0%; right: 0%; position: absolute; z-index: 10;">
                            {{ cards[0].id }}
                        </v-btn>
                        <div class="bg_color--1 pa-2"
                            :style="`width:auto; height: fit-content; text-break: normal; position: absolute; bottom: 0%; background-color:#dd8732; opacity: 0.9;padding: 20px 20px 60px 20px !important;`">
                            <h3 :style="`width: auto;`">
                                {{ cards[0].title }}</h3>
                            <p style="font-size: 15px;">
                                {{ cards[0].description }}
                            </p>
                        </div>
                    </v-card>
                </div>
            </div>
        </div>

        <div class="rn-contact-area bg_color--1" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-center mb--20">
                            <h4 class="heading-title">Our Solution in Telecommunication</h4>
                            <h3 class="heading-title mb-5">
                                Sibernetik's Solutions for the Telecommunications Industry
                            </h3>
                            <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                            <p style="text-align: center;" class="my-8">In the rapidly evolving landscape of
                                telecommunications, Sibernetik Integra Data stands as a vanguard of innovation and
                                expertise. Our deep understanding of the industry, combined with our mastery over
                                state-of-the-art technologies, positions us to address the unique challenges and harness the
                                immense opportunities of the telecommunications sector.</p>
                        </div>
                        <v-row class="service-one-wrapper hidden-mobile">
                            <!-- Start Single Service  -->
                            <v-col lg="6" md="12" sm="12" cols="12" v-for="(service, i) in marineItem" :key="i">
                                <div class="single-service service__style--siber d-flex align-center"
                                    style="height: 300px !important;">
                                    <!-- <router-link to="/service-details"> -->
                                    <div class="service d-flex flex-row">
                                        <div class="icon" v-html="iconSvg(service.icon)"></div>
                                        <div class="content ml-5">
                                            <h3 class="heading-title">{{ service.title }}</h3>
                                            <p>
                                                {{ service.desc }}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- </router-link> -->
                                </div>
                            </v-col>
                            <!-- End Single Service  -->
                        </v-row>
                        <v-row class="service-one-wrapper hidden-md-and-up">
                            <!-- Start Single Service  -->
                            <v-col lg="6" md="12" sm="12" cols="12" v-for="(service, i) in marineItem" :key="i"
                                style="scale:0.9;">
                                <div class="single-service service__style--siber d-flex align-center"
                                    style="height: auto !important;">
                                    <!-- <router-link to="/service-details"> -->
                                    <div class="service d-flex flex-row">
                                        <div class="icon" v-html="iconSvg(service.icon)"></div>
                                        <div class="content ml-5">
                                            <h3 class="heading-title">{{ service.title }}</h3>
                                            <p>
                                                {{ service.desc }}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- </router-link> -->
                                </div>
                            </v-col>
                            <!-- End Single Service  -->
                        </v-row>
                    </div>
                </v-container>
            </div>
        </div>

        <div class="rn-contact-area bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-left mb--20">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <h4 class="heading-title"> The Digital Symphony with Sibernetik Integra Data</h4>
                                        <h3 class="heading-title mb-5 hidden-mobile">
                                            From Service Providers to Technology Innovators
                                        </h3>
                                        <h3 class="heading-title mb-5 hidden-md-and-up" style="font-size: 20px;">
                                            From Service Providers to Technology Innovators
                                        </h3>
                                        <hr color="#dd8732" style="margin: auto; border-top: 4px  solid #dd8732">
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col lg="6" md="12" sm="12">

                                        <div style="position: relative;">
                                            <v-img
                                                src="https://inplatform.sibernetik.co.id/siber-web/images/futuristic-cityscape.jpg"
                                                width="auto" class="my-8" height="470px" style="z-index: 0;" />
                                            <v-card
                                                style="position:absolute; width: auto;bottom:0%; z-index: 1; opacity: 0.7; padding: 10px 20px;"
                                                flat tile>
                                                <v-card-title style="word-break: normal;">
                                                    <h4 class="heading-title">Let's come together to shape a digital
                                                        symphony
                                                    </h4>
                                                    <h3 class="hidden-mobile">
                                                        Where Every Note Resonates with Innovation
                                                    </h3>
                                                    <h3 class=" hidden-md-and-up" style="font-size: 20px;">
                                                        Where Every Note Resonates with Innovation
                                                    </h3>
                                                </v-card-title>
                                                <v-card-text>
                                                    <p style="text-align: left;">
                                                        With Sibernetik Integra Data as your trusted partner, you're not
                                                        just embracing the future; you're co-creating it.

                                                    </p>
                                                </v-card-text>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col lg="6" md="12" sm="12">

                                        <p style="text-align: left; " class="my-8">
                                            <!-- Sibernetik Integra Data, with its roots deeply embedded in the rich
                                            technological soil of Indonesia, understands the nuances, challenges, and
                                            aspirations of this industry. Our journey, which began in 2017, has been one of
                                            continuous learning, innovation, and commitment to excellence. We've witnessed
                                            the transformative power of telecommunications and have been instrumental in
                                            shaping its trajectory. -->
                                            In the vast orchestra of the telecommunications industry, each
                                            player contributes to the harmonious melody of global connectivity.
                                            As the
                                            world stands on the precipice of a digital revolution, the role of
                                            telecommunications becomes even more pivotal, weaving together the
                                            threads of innovation, security, and adaptability into a tapestry of
                                            endless possibilities.

                                        </p>
                                        <p style="text-align: left; " class="my-8">
                                            Our vision transcends the boundaries of technology. We envision a world where
                                            telecommunications is not just about connectivity but about creating meaningful,
                                            secure, and sustainable interactions. A world where data flows seamlessly,
                                            applications enhance everyday experiences, security is a given, and the future
                                            is crafted with foresight and expertise.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                    </div>
                </v-container>
            </div>
        </div>
        <!-- End Content -->

        <Footer />
    </div>
</template>
  
<script>
import HeaderOnePageTwo from "../../../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../../../components/header/HeaderOnePageTwoSiberMobile.vue";
import ServicePsre from "../../../components/service/ServicePsre.vue";
import Footer from "../../../components/footer/Footer";
import Card from '../../../components/portfolio/card.vue';
import feather from "feather-icons";

export default {
    components: {
        HeaderOnePageTwo,
        HeaderOnePageTwoMobile,
        ServicePsre,
        Footer,
        Card,
    },
    data: () => ({
        marineItem: [
            {
                // icon: "mdi-crosshairs-gps",
                icon: "activity",
                title: "Mastery in Real-time Data Handling",
                desc: "We ensure seamless data streaming and replication, optimizing network performance and enhancing user experiences. Our expertise in data management and analytics empowers businesses to drive informed decision-making, predictive insights, and proactive responses to market dynamics."
            },
            {
                // icon: "mdi-chart-line",
                icon: "code",
                title: "Application Engineering Prowess",
                desc: "Our team is adept at crafting next-generation telecom services using modern frameworks. We push the boundaries of geospatial data handling, a vital component for network optimization, service delivery, and location-based services in the telecommunications realm."
            },
            {
                // icon: "mdi-alert-outline",
                icon: "shield",
                title: "Unyielding Security Frameworks",
                desc: " In an era where digital threats are omnipresent, our robust security solutions ensure that telecom infrastructures remain fortified against external and internal threats. We offer real-time analysis of security alerts, ensuring the integrity, confidentiality, and availability of data across the spectrum."
            },
            {
                // icon: "mdi-monitor-multiple",
                icon: "layers",
                title: "Agility with Microservices and Real-time Databases",
                desc: "Our expertise in microservices architecture ensures agile, modular, and scalable solutions, vital for the dynamic needs of the telecommunications industry. Coupled with our proficiency in real-time databases, we guarantee high availability, performance, and uninterrupted services for end-users."

            },
        ],
        cards: [
            {
                id: 0,
                title: 'Sibernetik\'s Role: Pioneering the Future',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/brainstorming over a digital table.jpg',
                description: 'Our real-time transaction processing solution ensures that your customers can execute transactions swiftly, providing them with a seamless experience.',
            },
            {
                id: 1,
                title: 'The Evolution of Telecommunications',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/vintage_telephone.jpg',
                description: 'From the telegraph\'s inception to the current 5G revolution, telecommunications has been a realm of constant innovation, shaping societies and businesses.',
            },
            {
                id: 2,
                title: 'The Role of Data in Telecommunications',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/worlds-connected-digitally.jpg',
                description: 'Data drives decision-making, optimizes network performance, and enhances user experiences in the modern telecommunications landscape.',
            },
            {
                id: 3,
                title: 'Indonesia\'s Telecom Landscape',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/indonesia-HI.jpg',
                description: 'Indonesia\'s diverse landscape offers a unique blend of challenges and opportunities in the telecommunications domain.',
            },
            {
                id: 4,
                title: 'Sustainability in Telecommunications',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/green-telecommunication.jpg',
                description: 'The telecom industry is pivoting towards sustainable solutions, emphasizing green telecommunications and reduced carbon footprints.',
            },
            // Tambahkan data kartu selanjutnya
        ],
        onHoverChallenge: '0',
        insights: [
            {
                id: 0,
                icon: "https://inplatform.sibernetik.co.id/siber-web/images/A bustling city with data streams flowing through skyscrapers.jpg",
                title: "Current State: The Digital Surge",
                desc: "The rise of smart devices and the global anticipation of 5G are driving an unprecedented surge in data traffic."
            },
            {
                id: 1,
                icon: "https://inplatform.sibernetik.co.id/siber-web/images/A maze made of digital circuits, symbolizing challenges.jpg",
                title: "Challenges Ahead: The Digital Quagmire",
                desc: "Infrastructure needs, security threats, and evolving consumer demands shape the industry's trajectory."
            },
            {
                id: 2,
                icon: "https://inplatform.sibernetik.co.id/siber-web/images/digital_transformation.jpg",
                title: "The Future: Beyond Connectivity",
                desc: "The industry is gearing up for a holistic digital transformation, with AI-driven optimizations and augmented realities."
            },
        ],
    }),
    methods: {
        iconSvg(icon) {
            return feather.icons[icon].toSvg();
        },
        slideLeft() {
            // Metode untuk menggeser kartu ke kiri (Previous)
            const lastCard = this.cards.pop();
            this.cards.unshift(lastCard);
        },
        slideRight() {
            // Metode untuk menggeser kartu ke kanan (Next)
            const firstCard = this.cards.shift();
            this.cards.push(firstCard);
        },
    },
    watch: {

    }
};
</script>
<style scoped>
.centang {
    vertical-align: top;
    min-width: 17px;
    padding-top: 5px;
    margin-bottom: 10px !important;
}

.feather {
    width: 24px;
    height: 24px;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}

.heading-title1 {
    color: #dd8732 !important;
    line-height: 60px;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}

.card-slider {
    grid-row: 1 / 2;
    grid-column: 1 / 8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.card-container {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(5, 1fr);
    /* Atur jumlah kolom kartu sesuai kebutuhan */
    grid-gap: 10px;
    /* Jarak antar kartu */
    overflow: hidden;
    padding: 20px;
}

.buttons {
    margin-top: 20px;
    text-align: center;
}

.buttons button {
    margin: 5px;
    padding: 10px 20px;
    /* background-color: #007bff; */
    /* color: #fff; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.buttons button:hover {
    /* background-color: #0056b3; */
    font-weight: 400;
}

.card-solutions-0 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: -80%;
    z-index: 8;
    transform: scale(0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-1 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: -30%;
    z-index: 9;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-2 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-3 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    left: -30%;
    z-index: 9;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-4 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    left: -80%;
    z-index: 8;
    transform: scale(0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-5 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    display: none;
}
</style>
  